import { useQuery } from 'react-query';
import { CompanyAPI } from '../api/company';
import { API } from '../services/api';
import { NotificationsResponse } from '../types/notifications';

interface ListProps {
  size: number;
  page: number;
}
export const useNotificationsQuery = (params: ListProps) => {
  const { size, page } = params;
  return useQuery<
    { message: string },
    { error: { message: string } },
    NotificationsResponse
  >(
    ['get-notifications-list', size, page],
    async () => await API.getter(CompanyAPI.notifications(params)),
    {
      staleTime: 10 * 1000,
      refetchOnWindowFocus: 'always',
    },
  );
};
