import { atom } from 'recoil';
import { localstorageKeys } from '../services/local-storage-keys';

export const authState = atom({
  key: 'auth', // unique ID
  default: {
    token: localStorage.getItem(localstorageKeys.token),
    refreshToken: localStorage.getItem(localstorageKeys.refreshToken),
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((tokens) => {
        if (tokens.token && tokens.refreshToken) {
          localStorage.setItem(localstorageKeys.token, tokens.token);
          localStorage.setItem(
            localstorageKeys.refreshToken,
            tokens.refreshToken,
          );
        }
      });
    },
  ],
});
