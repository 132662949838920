import { atom } from 'recoil';
import { localstorageKeys } from '../services/local-storage-keys';
import { CompanyDataResponse, CompanyTypes } from '../types/company';

export const companyDataState = atom<CompanyDataResponse>({
  key: 'company-data', // unique ID
  default: (JSON.parse(
    localStorage.getItem(localstorageKeys.companyData) as string,
  ) as CompanyDataResponse) || {
    user: {
      unseenNotifications: 0,
    },
    subscriptionStatus:
      localStorage.getItem(localstorageKeys.companyStatus) || 'paid',
    company: {
      logo: '',
      name: '',
      branches: [],
      serviceStatus: '',
      status: '',
      premiumLinesMonitoring:false,
    },
    isHasSavedCards: false,
    isHasOutstandingPayment: false,
    subscriptionInfo: {
      nextPaymentDate: '',
      total: 0,
    },
    isPremium:false
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((companyData) => {
        localStorage.setItem(
          localstorageKeys.companyData,
          JSON.stringify(companyData),
        );

        if (companyData.subscriptionStatus) {
          localStorage.setItem(
            localstorageKeys.companyStatus,
            companyData.subscriptionStatus,
          );
        }
      });
    },
  ],
});
