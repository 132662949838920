import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

export const breakpoints = createBreakpoints({});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

// A custom theme for this app
let theme = createTheme({
  palette: {
    background: {
      default: '#fafbfc',
      paper: '#fff',
    },
    primary: {
      main: '#101928',
      contrastText: '#fff',
      dark: '#0f0a07',
      light: '#d6dadf',
    },
    secondary: {
      main: '#0076ff',
      dark: '#01346f',
      light: '#e5f1ff',
      contrastText: '#fff',
    },
    neutral: {
      main: '#eef0f2',
      contrastText: '#101928',
    },
    error: {
      main: '#da2346',
      dark: '#83011a',
      light: '#fbe9ec',
      contrastText: '#fff',
    },
    warning: {
      main: '#fbd64d',
      contrastText: '#fff',
      dark: '#663c00',
      light: '#f8f0d5',
    },
    info: {
      dark: '#fbd64d',
      light: '#fff',
      main: '#663c00',
      contrastText: '#f8f0d5',
    },
    success: {
      main: '#01a36d',
      dark: '#015e3f',
      light: '#e5f5f0',
      contrastText: '#fff',
    },
    text: {
      primary: '#101928',
      secondary: '#68758c',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontFamily: ['Inter'].join(','),
    body1: {
      color: '#101928',
    },
    body2: {
      color: '#68758c',
    },
    h1: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
    h2: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
    h3: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
    h4: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
    h5: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
    h6: {
      fontFamily: ['Poppins'].join(','),
      color: '#241c15',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '15px',
          fontWeight: 500,
          borderRadius: 4,
          boxShadow: 'none',
          textTransform: 'none',
          padding: '0px 24px',
          minHeight: '44px',
          ':hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          ':hover': {
            boxShadow: 'none',
            background: '#343b48',
          },
        },
        text: {
          ':hover': {
            background: 'none',
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          fontsize: '14px',
          fontWeight: '400',
        },
        filledSuccess: {
          color: '#101928',
          backgroundColor: '#e5f5f0',
        },
        filledError: {
          backgroundColor: '#fbe9ec',
          color: '#101928',
        },
        filledInfo: {
          backgroundColor: '#0076ff',
          color: '#fff',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: '#241c15',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0px',
          marginTop: '8px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: '#0076ff',
          height: '4px',
          borderTopLeftRadius: '14px',
          borderTopRightRadius: '14px',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        checked: {},
        root: {
          ':hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#101928',
          minWidth: 'auto',
          alignItems: 'flex-start',
          textTransform: 'none',
          // minWidth: 162,
          '&.Mui-selected': {
            color: '#0076ff',
            fontWeight: 600,
          },
          [breakpoints.between('xs', 'sm')]: {
            minWidth: 120,
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        dot: {
          width: '12px',
          height: '12px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#8c8c8c',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: '#101928',
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          minWidth: 750,
          backgroundColor: '#EEF0F2',
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: 'initial',
        },
      },
    },

    MuiTableRow: {
      defaultProps: {
        hover: true,
      },

      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
        head: {
          cursor: 'default',
          backgroundColor: '#EEF0F2',
          ':hover': {
            backgroundColor: '#EEF0F2',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEF0F2',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: '#EEF0F2',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#101928',
        },
        tooltip: {
          fontSize: '14px',
          padding: '16px',
          color: 'white',
          backgroundColor: '#101928',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: '#101928',
          fontWeight: 500,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
