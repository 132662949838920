import login from './auth/login/ar';
import forgotPassword from './auth/forgot-password/ar';
import resetPassword from './auth/reset-password/ar';
import register from './auth/register/ar';

import teamMembers from './team-members/ar';
import billing from './billing/ar';
import companies from './companies/ar';

const locales = {
  auth: {
    login,
    forgotPassword,
    resetPassword,
    register,
  },
  teamMembers,
  billing,
  companies,
};

export default locales;
