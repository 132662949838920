import React from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteChildrenProps,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authState } from '../../store/auth';

interface IProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

export const PublicRoute: React.FC<IProps> = ({ children, ...rest }) => {
  const [auth] = useRecoilState(authState);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/management',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
