import axios from 'axios';
import { localstorageKeys } from '../services/local-storage-keys';

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add axios interceptor to inject the token to requests
  instance.interceptors.request.use((request: any) => {
    const token = localStorage.getItem(localstorageKeys.token);

    if (token) {
      request.headers['Authorization'] = token;
    }

    return request;
  });

  // Add axios interceptor for generic error handling
  instance.interceptors.response.use(undefined, function (error: any) {
    // error.handleGlobally = genericErrorHandler(error);

    const errorMessage = error?.response?.data?.error?.message;
    const isUnauthorized = errorMessage === 'unauthorized';
    if (isUnauthorized) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    return Promise.reject(error);
  });

  return instance;
};

export const createWinFiAdminAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add axios interceptor for generic error handling
  instance.interceptors.response.use(undefined, function (error: any) {
    // error.handleGlobally = genericErrorHandler(error);

    const errorMessage = error?.response?.data?.error?.message;
    const isUnauthorized = errorMessage === 'unauthorized';
    if (isUnauthorized) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    return Promise.reject(error);
  });

  return instance;
};

// main core api
export const coreAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_CORE_BASE_API_URL as string,
);

// core api
export const baseAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_CORE_API_URL as string,
);

// mock api
export const mockAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_CORE_MOCK_API_URL as string,
);

// WinFi admin api
export const WinFiAdminAxiosInstance = createWinFiAdminAxiosInstance(
  process.env.REACT_APP_ADMIN_API_URL as string,
);

// Analytics api
export const analyticsAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_ANALYTICS_API_URL as string,
);

// Marketing api
export const marketingAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_MARKETING_API_URL as string,
);

// Door Access api
export const doorAccessAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_DOOR_ACCESS_API_URL as string,
);
// Lines Monitoring api
export const networkAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_NETWORK_API_URL as string
 );