import moment from 'moment';

export const daysUntilServiceEnd = (): number => {
  const today = moment();
  const startOfCurrentMonth = moment().startOf('month');
  const endDateOfCurrentMonth = startOfCurrentMonth.add(7, 'days');
  // banner appears from 1st to 20th so "today" will always be less than 20th
  const diffDays = endDateOfCurrentMonth.diff(today, 'days');
  return diffDays;
};
