interface PropTypes {
  size: number;
  page: number;
}
const companyData = '/company/updated-data';
const billingData = '/company/billing/info';
const branchesPrices = '/company/billing/branches-prices';
const billingHistory = (parmas: PropTypes) =>
  `/company/billing/history/?page=${parmas.page}&size=${parmas.size}`;
const transactionsHistory = (parmas: PropTypes) =>
  `/company/billing/transactions/?page=${parmas.page}&size=${parmas.size}`;
const payWithBankSlip = '/company/billing/bank-slip/pay';
const notifications = (params: PropTypes) =>
  `/account/notifications?page=${params.page}&size=${params.size}`;

const getBranches = 'company/branches/branches-and-assets';
const companyBasicInfo = 'company/info';
const getWalletPackage ='company/billing/wallet-packages'
export const CompanyAPI = {
  companyData,
  billingData,
  branchesPrices,
  billingHistory,
  transactionsHistory,
  payWithBankSlip,
  notifications,
  getBranches,
  companyBasicInfo,
  getWalletPackage,
};
