import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import EN from './locales/en';
import AR from './locales/ar';

// react-i18next versions higher than 11.11.0
declare module 'react-i18next' {
  // and extend them!
  interface CustomTypeOptions {
    // custom resources type
    resources: {
      ns1: typeof EN;
      ns2: typeof AR;
    };
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: { ...EN },
      },
      ar: {
        translation: { ...AR },
      },
    },
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const getCurrentDirection = (): 'ltr' | 'rtl' =>
  i18n.language === 'ar' ? 'rtl' : 'ltr';
