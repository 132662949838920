const login = '/account/login';
const forgotPassword = '/account/forget-password';
const verifyToken = (token: string) => `/account/verify/${token}`;
const verifyFirstLoginToken = (token: string) =>
  `/staff/verify-invitation/${token}`;
const resetPassword = '/account/reset-password';
const register = '/account/signup';
const verifyCode = (code: string) => `/account/verify-code/${code}`;
const resendCode = '/account/resend-validation-code';
const reEnterEmail = '/account/email';
const companyInfo = '/account/company-info';
const twoFactorAuthentication = '/account/two-factor-authentication';
const firstLogin = '/staff/login';
const logout = '/account/logout';
const livePreview = (companyId: string) =>
  `/companies/live-preview-access/${companyId}`;

export const AuthAPI = {
  login,
  forgotPassword,
  verifyToken,
  verifyFirstLoginToken,
  resetPassword,
  register,
  verifyCode,
  resendCode,
  reEnterEmail,
  companyInfo,
  firstLogin,
  logout,
  livePreview,
  twoFactorAuthentication,
};
