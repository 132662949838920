import login from './auth/login/en';
import forgotPassword from './auth/forgot-password/en';
import resetPassword from './auth/reset-password/en';
import register from './auth/register/en';

import teamMembers from './team-members/en';
import billing from './billing/en';
import companies from './companies/en';

const locales = {
  auth: {
    login,
    forgotPassword,
    resetPassword,
    register,
  },
  teamMembers,
  billing,
  companies,
};

export default locales;
