import { atom } from 'recoil';
import { localstorageKeys } from '../services/local-storage-keys';

export const userState = atom({
  key: 'user', // unique ID
  default: JSON.parse(
    localStorage.getItem(localstorageKeys.user) ?? '{}',
  ) as any,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((user) => {
        localStorage.setItem(localstorageKeys.user, JSON.stringify(user));
      });
    },
  ],
});

